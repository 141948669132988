@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul { 
  display: block; 
  list-style-type: disc; 
  /* -webkit-margin-before: 1em; 
  -webkit-margin-after: 1em; 
  -webkit-margin-start: 0px; 
  -webkit-margin-end: 0px;  */
  -webkit-padding-start: 40px;
}

ol { 
  display: block; 
  list-style-type: decimal; 
  -webkit-padding-start: 40px;
}